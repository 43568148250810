import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  BonusNotificationWrapper,
  BonusNotificationHeader,
  BonusNotificationIcon,
  BonusNotificationHeaderText,
  BonusNotificationBody,
  ChartImage
} from '../../reprojectionPageStyle';
import { getImagePath } from '../../../utils/routeUtils';

export const checkKeyValueName = (keyValue, keyName) =>
  _.isArray(keyValue) && keyName === 'policyEffectiveDate';

export const compareDates = (
  allConditionsValue,
  dateFromApiInMS,
  policyEffectiveStartDate,
  policyEffectiveEndDate
) =>
  allConditionsValue &&
  dateFromApiInMS >= policyEffectiveStartDate &&
  dateFromApiInMS <= policyEffectiveEndDate;

export const areConditionsSatisfied = (
  conditions,
  selectedTabName,
  reprojectionsDetails,
  allConditions
) => {
  const conditionsArray = conditions;
  const currentTab = selectedTabName;
  const reprojectionObj = reprojectionsDetails;
  let allConditionsValue = allConditions;
  _.each(conditionsArray, condition => {
    const { type, fieldParent, keyName, keyValue } = condition;
    switch (type) {
      case 'PROPS_COMPARE':
        allConditionsValue =
          allConditionsValue && _.includes(keyValue, currentTab);
        return allConditionsValue;
      case 'DATE_BEFORE': {
        const dateFromApi = _.get(reprojectionObj, `${fieldParent}.${keyName}`);
        if (dateFromApi) {
          const dateFromApiInMS = new Date(dateFromApi).getTime();
          const dateValueInMS = new Date(keyValue).getTime();
          allConditionsValue =
            allConditionsValue && dateFromApiInMS < dateValueInMS;
        } else {
          allConditionsValue = false;
        }
        return allConditionsValue;
      }
      case 'DATE_AFTER': {
        const dateFromApi = _.get(reprojectionObj, `${fieldParent}.${keyName}`);
        if (dateFromApi) {
          const dateFromApiInMS = new Date(dateFromApi).getTime();
          if (checkKeyValueName(keyValue, keyName)) {
            const policyEffectiveStartDate = new Date(keyValue[0]).getTime();
            const policyEffectiveEndDate = new Date(keyValue[1]).getTime();
            allConditionsValue = compareDates(
              allConditionsValue,
              dateFromApiInMS,
              policyEffectiveStartDate,
              policyEffectiveEndDate
            );
          } else {
            const dateValueInMS = new Date(keyValue).getTime();
            allConditionsValue =
              allConditionsValue && dateFromApiInMS >= dateValueInMS;
          }
        } else {
          allConditionsValue = false;
        }
        return allConditionsValue;
      }
      default:
        allConditionsValue =
          allConditionsValue &&
          _.includes(
            keyValue,
            _.get(reprojectionObj, `${fieldParent}.${keyName}`)
          );
        return allConditionsValue;
    }
  });
  return allConditionsValue;
};

export const getNotification = (
  selectedTabName,
  reprojectionsDetails,
  sectionNotificationConfig
) => {
  const { notifications } = sectionNotificationConfig;
  let notificationsDetails = null;
  _.each(notifications, notification => {
    const {
      conditions,
      dateComparisonField,
      startDate,
      endDate,
      display
    } = notification;
    const currentDate = _.get(reprojectionsDetails, dateComparisonField); // policy valuation date
    const currentDateInMS = currentDate ? new Date(currentDate).getTime() : '';
    const startDateInMS = new Date(startDate).getTime();
    const endDateInMS = new Date(endDate).getTime();
    let allConditionsSatisfied = false;
    if (currentDateInMS) {
      if (currentDateInMS >= startDateInMS && currentDateInMS <= endDateInMS) {
        allConditionsSatisfied = areConditionsSatisfied(
          conditions,
          selectedTabName,
          reprojectionsDetails,
          true
        );
      }
      if (allConditionsSatisfied) {
        notificationsDetails = display;
        return false;
      }
    }
    return true;
  });
  return notificationsDetails;
};

const BelowGraphNotification = function BelowGraphNotification(props, context) {
  const {
    selectedTabName,
    reprojectionsDetails,
    sectionNotificationConfig
  } = props;

  const {
    intl: { formatMessage }
  } = context;
  const hasNotification = getNotification(
    selectedTabName,
    reprojectionsDetails,
    sectionNotificationConfig
  );
  if (hasNotification) {
    const {
      sectionName,
      sectionImage,
      content: { key }
    } = hasNotification;
    return (
      <>
        <BonusNotificationWrapper>
          <BonusNotificationHeader>
            <BonusNotificationIcon>
              <ChartImage
                aria-hidden="true"
                alt=""
                src={getImagePath(process.env.PUBLIC_URL, sectionImage)}
              />
            </BonusNotificationIcon>
            <BonusNotificationHeaderText>
              {formatMessage({ id: sectionName })}
            </BonusNotificationHeaderText>
          </BonusNotificationHeader>
          <BonusNotificationBody>
            {formatMessage({ id: key })}
          </BonusNotificationBody>
        </BonusNotificationWrapper>
      </>
    );
  }
  return null;
};

BelowGraphNotification.propTypes = {
  selectedTabName: PropTypes.string.isRequired,
  reprojectionsDetails: PropTypes.object.isRequired,
  sectionNotificationConfig: PropTypes.object.isRequired
};

BelowGraphNotification.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BelowGraphNotification;
